<script>
import postsService from '@/services/posts.service'
import lazyType from '@/utils/lazy-type'

export default {
  name: 'Posts',
	data() {
		return {
      items: [],
      categories: [],
      limit: 20,
      offset: 1,
      totalItems: 0,
      activeCategoryTitle: `${this.$t('all')} ${this.$tc('category', 2)}`,
      activeAuthorTitle: `${this.$t('all')} ${this.$tc('author', 2)}`,
      category: '',
      author: '',
      keyword: "",
      lazyTypeDelay: 200,
      headerFields: [
        "__slot:checkboxes",
        {
          name: "published",
          label: this.$t('published'),
          customElement: "published",
        },
        {
          name: "photo",
          label: this.$tc('photo'),
          customElement: "photo",
        },
        {
          name: "title",
          label: this.$t('title'),
          customElement: "title",
        },
        {
          name: "category",
          label: this.$tc('category', 2),
          format: cats => cats.map(cat => cat.name).join(', '),
        },
        {
          name: "author",
          label: this.$tc('author'),
          format: author => author.username,
        },
        {
          name: "changed_at",
          label: this.$t('lastUpdated'),
          format: date => new Date(date).toLocaleString('en-GB'),
        },
        "__slot:actions:edit",
        "__slot:actions:delete",
      ],
		}
  },
  computed: {
    users() {
      return this.$store.state.users.users
    }
  },
	mounted() {
    this._get()
    this._getAllCategories()
	},
	methods: {
    lazyType,
    _get(resetOffset = true) {
      if(resetOffset) this.offset = 1
			postsService.getAll({ category: this.category, author: this.author, limit: this.limit, offset: this.offset, keyword: this.keyword }).then(
				res => {
          this.items = res.data.map(item => {
            item.changed_at = item.updated_at || item.created_at
            return item
          })
          this.totalItems = Number(res.headers['x-items-count']) || 0
				}
			)
    },
    _getAllCategories() {
      postsService.getAllCategories()
        .then(res => {
          this.categories = res.data
        })
    },
    filterByCategory(category) {
      this.activeCategoryTitle = category.name || `${this.$t('all')} ${this.$tc('category', 2)}`
      this.category = category.alias
      this._get()
    },
    filterByAuthor(author) {
      this.activeAuthorTitle = author.username || `${this.$t('all')} ${this.$tc('author', 2)}`
      this.author = author.alias || ''
      this._get()
    },
    filterBySearch() {
      this._get()
    },
    updatePagination(offset) {
      this.offset = offset
      this._get(false)
    },
    remove(id) {
      postsService.remove({ id })
        .then(success => {
          this.$notify({
              message: this.$t('m.itemSuccessfullyDeleted', { item: this.$tc('post') }),
              icon: "far fa-bell",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            })
          const index = this.items.findIndex(obj => obj.id === id)
          this.items.splice(index, 1)
        })
    }
	},
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="form-group col-12 text-right">
          <router-link :to="{ name: 'singlePost', params: { id: 'new' } }">
            <BaseButton
              type="green"
              gradient
            >
              {{ $t('create') }} {{ $tc('post') }}
            </BaseButton>
          </router-link>
        </div>
      </div>
      <div class="row justify-content-between align-items-end">
        <div class="col-md-3">
          <label class="control-label">{{ $t('filterBy', { item: $tc('category') }) }}</label>
          <BaseDropdown
            title-classes="btn btn-secondary"
            :title="activeCategoryTitle"
          >
            <a
              class="dropdown-item"
              @click="filterByCategory('')"
            >{{ $t('all') }} {{ $tc('category', 2) }}</a>
            <a
              v-for="cat of categories"
              :key="cat.alias+'category'"
              class="dropdown-item"
              @click="filterByCategory(cat)"
            >{{ cat.name }}</a>
          </BaseDropdown>
        </div>
        <div class="col-md-3">
          <label class="control-label">{{ $t('filterBy', { item: $tc('author') }) }}</label>
          <BaseDropdown
            title-classes="btn btn-secondary"
            :title="activeAuthorTitle"
          >
            <a
              class="dropdown-item"
              @click="filterByAuthor('')"
            >{{ $t('all') }} {{ $tc('author', 2) }}</a>
            <a
              v-for="user in users"
              :key="user.username+'author'"
              class="dropdown-item"
              @click="filterByAuthor(user)"
            >{{ user.username }}</a>
          </BaseDropdown>
        </div>
        <div class="col-md-3">
          <BaseInput
            v-model="keyword"
            :placeholder="$t('search')"
            class="search"
            @keyup.prevent.stop="lazyType(filterBySearch, lazyTypeDelay)"
          />
        </div>
      </div>
    </div>
    <div class="card-body">
      <BaseListTable
        :items="items"
        :total-items="totalItems"
        :limit="limit"
        :offset="offset"
        :header-fields="headerFields"
        type="posts"
        has-pagination
        @updatePagination="updatePagination"
        @delete="remove"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:math';
@use '~@/assets/sass/design.sass' as *;

.search {
  margin-bottom: math.div($base-spacing, 4);
}
</style>
